import { FC, useMemo } from 'react';
import User from '../../../models/User';
import Tooltip from '../Tooltip';
import ProfileInitials from './ProfileInitials';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import { useTranslation } from 'react-i18next';
import { ClientFormUserRoleValues } from '../../../models/ClientFormUserRoles';
import useAvatarRoleNames from '../../../hooks/useAvatarRoleNames';

export type AvatarRole = {
  role: ClientFormUserRoleValues;
  requiresAction: boolean;
  roleOrder: number | undefined | null;
  isStepRole: boolean;
};

type AvatarProps = {
  user?: User;
  roles?: AvatarRole | AvatarRole[];
  size?: ImageSize;
  withMargin?: boolean;
  className?: string;
};

export enum ImageSize {
  XS,
  S,
  M,
}

const ProfileAvatar: FC<AvatarProps> = (props) => {
  const { user, size, withMargin, className = '', roles } = props;
  let sizeCss = 'w-10 h-10 text-dpm-14';
  const marginCss = withMargin ? '-ml-2' : '';
  const { t } = useTranslation(['common']);

  switch (size) {
    case ImageSize.XS:
      sizeCss = 'min-w-6 w-6 h-6 text-xs';
      break;
    case ImageSize.S:
      sizeCss = 'min-w-8 w-8 h-8 text-dpm-12';
      break;
    default:
      sizeCss = 'min-w-10 w-10 h-10 text-dpm-14';
      break;
  }

  const roleNames = useAvatarRoleNames(roles || [], t);

  return (
    <Tooltip
      text={
        user?.firstName &&
        user?.lastName && (
          <>
            <div>
              {user.firstName} {user.lastName}
            </div>
            <div className="text-gray-3">{roleNames}</div>
          </>
        )
      }
    >
      {(tooltip) => (
        <div {...tooltip} className="flex w-fit flex-col justify-center">
          {!user?.profileImageUrl ? (
            <ProfileInitials
              firstName={user?.firstName ?? ''}
              lastName={user?.lastName ?? ''}
              id={user?.id ?? ''}
              className={className}
              marginCss={marginCss}
              sizeCss={sizeCss}
              {...dataAttributeProps(props)}
            />
          ) : (
            <img
              alt={user?.fullName}
              src={user?.profileImageUrl}
              className={`${className} ${marginCss} rounded-full bg-white transition-all duration-300 ease-in-out ${sizeCss} inline-flex cursor-default justify-center`}
            />
          )}
        </div>
      )}
    </Tooltip>
  );
};

export default ProfileAvatar;
