import { useMemo } from 'react';
import { ClientFormUserRole, ClientFormUserRoleKeys } from '../models/ClientFormUserRoles';
import { AvatarRole } from '../components/shared/profile-image/ProfileAvatar';
import { Namespace, TFunction } from 'i18next';

const useAvatarRoleNames = (roles: AvatarRole | AvatarRole[], t: TFunction<Namespace>) => {
  return useMemo(() => {
    if (!roles) return '';

    const roleArray = Array.isArray(roles) ? roles : [roles];

    return roleArray
      .map((role) => {
        if (role.role === ClientFormUserRole.Owner && role.requiresAction) {
          return t('form-role-tooltip-owner');
        }

        let order = '';
        let actionRequired = '';

        if (role.roleOrder !== undefined && role.roleOrder !== null) {
          order = `#${role.roleOrder + 1}`;
        }
        if (role.role === ClientFormUserRole.Approver || role.role === ClientFormUserRole.Validator) {
          actionRequired = role.requiresAction || role.isStepRole ? t('form-role-requiredness.required') : t('form-role-requiredness.optional');
        }

        return t('form-role-tooltip', { actionRequired: actionRequired, role: t(ClientFormUserRoleKeys[role.role]), order: order });
      })
      .map((roleText, index) => (
        <div key={index} className="text-nowrap">
          {roleText}
        </div>
      ));
  }, [roles, t]);
};

export default useAvatarRoleNames;
